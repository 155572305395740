import axios from './axios-config';

export const loginService = (reqData) => {
  return axios.post(`/auth/login`, reqData);
};

export const getUser = () => {
  const userData = localStorage.getItem('user');
  if (userData) return JSON.parse(userData);
  return null;
};
export const setUser = (userData) => userData && localStorage.setItem('user', JSON.stringify(userData));
export const removeUser = () => localStorage.removeItem('user');
