import React, { useState, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import PageLoader from '../PageLoader';
import AppLocale from '../../i18n';
import { setAuthUser } from '../../redux/actions/auth';
import { createTheme } from '@material-ui/core';
const theme = createTheme();

const AppWrapper = ({ children }) => {
  const { lang } = useSelector(({ common }) => common);
  const { loadUser } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();
  const [currentAppLocale, setCurrentAppLocale] = useState(AppLocale[lang]);

  useEffect(() => {
    setCurrentAppLocale(AppLocale[lang]);
  }, [lang]);

  useEffect(() => {
    dispatch(setAuthUser());
  }, []);

  return (
    <IntlProvider locale={currentAppLocale?.locale} messages={currentAppLocale?.messages}>
      <ThemeProvider theme={theme}>{loadUser ? children : <PageLoader />}</ThemeProvider>
    </IntlProvider>
  );
};

AppWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};

export default AppWrapper;
