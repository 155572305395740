import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import GridContainer from '../../../components/GridContainer';
import TextfieldWrapper from '../../../components/FormikWrappers/TextfieldWrapper';
import { Grid, Select, FormControl, InputLabel, MenuItem, FormHelperText, Checkbox, ListItemText } from '@material-ui/core';
import { addPlan, updatePlan } from '../../../redux/actions/users';
import { useDispatch } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';

import '../../Users/NewUser/newuser.scss';
import { format } from 'date-fns';

const NewPlan = ({ open, handleClose, handleOk, plan, users }) => {
  const formRef = useRef();
  const dispatch = useDispatch();

  const [validationSchema, setValidationSchema] = useState(Yup.object().shape({
    userIds: Yup.array().min(1, 'Please select atleast one user').required('Please select atleast one user'),
    date: Yup.string().required('This is Required Field'),
    location: Yup.string().required('This is Required Field'),
    dropOff: Yup.string().required('This is Required Field'),
    pickup: Yup.string().required('This is Required Field'),
    todo: Yup.string().required('This is Required Field'),
    contact: Yup.string().required('This is Required Field'),
    phoneNo: Yup.string().required('This is Required Field'),
    company: Yup.string().required('This is Required Field'),
    workWith: Yup.string()
  }));

  const [initialFormState, setInitialFormState] = useState({
    userId: '',
    date: new Date(),
    location: '',
    dropOff: '',
    pickup: '',
    todo: '',
    contact: '',
    phoneNo: '',
    company: '',
    userIds: [],
    workWith:''
  });

  useEffect(() => {
    if (plan && plan.id) {
      const dateTime = plan?.date ? new Date(plan.date) : new Date();
      if (plan.date && plan.time) {
        const time = plan.time.split(':');
        dateTime.setHours(time[0], time[1], 0);
      }
      setInitialFormState({
        userId: plan.userId,
        date: dateTime,
        location: plan?.location || '',
        dropOff: plan?.dropOff || '',
        pickup: plan?.pickup || '',
        todo: plan?.todo || '',
        contact: plan?.contact || '',
        phoneNo: plan?.phoneNo || '',
        company: plan?.company || '',
        workWith: plan?.workWith || '',
      });
      setValidationSchema(
        Yup.object().shape({
          userId: Yup.string().required('User is required'),
          date: Yup.string().required('This is Required Field'),
          location: Yup.string().required('This is Required Field'),
          dropOff: Yup.string().required('This is Required Field'),
          pickup: Yup.string().required('This is Required Field'),
          todo: Yup.string().required('This is Required Field'),
          contact: Yup.string().required('This is Required Field'),
          phoneNo: Yup.string().required('This is Required Field'),
          company: Yup.string().required('This is Required Field'),
          workWith: Yup.string()
        })
      )
    }
  }, []);

  const submitForm = (values) => {
    const data = {
      ...values,
      date: format(values.date, 'yyyy-MM-dd'),
      time: `${new Date(values.date).getHours()}:${new Date(values.date).getMinutes() < 10 ? '0' : ''}${new Date(
        values.date
      ).getMinutes()}`
    };
    if (plan && plan.id) {
      delete data.userIds;
      dispatch(
        updatePlan(plan.id, { ...data }, () => {
          handleOk();
        })
      );
    } else {
      delete data.userId;
      dispatch(
        addPlan({ ...data }, () => {
          handleOk();
        })
      );
    }
  };

  const save = () => {
    formRef.current.submitForm();
  };

  return (
    <Dialog maxWidth={'md'} fullWidth onClose={handleClose} open={open}>
      <DialogTitle className="">{plan ? 'Update E-Plan' : 'New E-Plan'}</DialogTitle>
      <DialogContent dividers={true}>
        <Formik
          innerRef={formRef}
          initialValues={{ ...initialFormState }}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={(values) => submitForm(values)}
        >
          {({ values, setFieldValue, errors, setFieldTouched, touched }) => (
            <Form>
              <GridContainer className="newuser__container">
                {plan?.id ?
                  (<Grid item xs={12} md={6} lg={4}>
                    <FormControl fullWidth variant="outlined" error={touched?.userId && errors?.userId}>
                      <InputLabel>User</InputLabel>
                      <Select
                        // disabled={plan && plan.id}
                        value={values.userId}
                        label="User"
                        onChange={({ target: { value } }) => setFieldValue('userId', value)}
                        onBlur={() => setFieldTouched('userId', true)}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {users
                          .filter(({ role }) => role === 2)
                          .map((user) => (
                            <MenuItem value={user.id} key={user.id}>{`${user.firstname} ${user.lastname}`}</MenuItem>
                          ))}
                      </Select>
                      {touched?.userId && errors?.userId && <FormHelperText error>{errors?.userId}</FormHelperText>}
                    </FormControl>
                  </Grid>) :
                  (<Grid item xs={12} md={6} lg={4} onClick={() => setFieldTouched('userIds', true)}>
                    <FormControl fullWidth variant="outlined" error={touched?.userIds && errors?.userIds}>
                      <InputLabel>Users</InputLabel>
                      <Select
                        value={values.userIds}
                        label="User"
                        multiple
                        onChange={({ target: { value } }) => setFieldValue('userIds', value)}
                        renderValue={(selected) => {
                          return selected.map((user) => {
                            const userData = users.find((u) => u.id === user);
                            return `${userData.firstname} ${userData.lastname}`;
                          }
                          ).join(', ');
                        }}
                      >
                        {users
                          .filter(({ role }) => role === 2)
                          .map((user) => (
                            <MenuItem value={user.id} key={user.id}>
                              <Checkbox checked={values.userIds.indexOf(user.id) > -1} />
                              <ListItemText primary={`${user.firstname} ${user.lastname}`} />
                            </MenuItem>
                          ))}
                      </Select>
                      {touched?.userIds && errors?.userIds && <FormHelperText error>{errors?.userIds}</FormHelperText>}
                    </FormControl>
                  </Grid>
                  )}
                <Grid item xs={12} md={6} lg={4}>
                  <TextfieldWrapper autoComplete="off" name="company" label="Company" placeholder="Company" />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextfieldWrapper autoComplete="off" name="contact" label="Contact" placeholder="Contact" />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextfieldWrapper autoComplete="off" name="phoneNo" label="PhoneNo" placeholder="PhoneNo" />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                      label="DateTimePicker"
                      inputVariant="outlined"
                      value={values.date}
                      onChange={(e) => setFieldValue('date', e)}
                      style={{ width: '100%' }}
                      clearable={false}
                      minDate={plan ? null : new Date()}
                      showTodayButton
                      // autoOk
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextfieldWrapper autoComplete="off" name="location" label="Location" placeholder="Location" />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextfieldWrapper autoComplete="off" name="dropOff" label="DropOff" placeholder="DropOff" />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextfieldWrapper autoComplete="off" name="pickup" label="Pickup" placeholder="Pickup" />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextfieldWrapper autoComplete="off" name="todo" label="Todo" placeholder="Todo" />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextfieldWrapper autoComplete="off" name="workWith" label="work With" placeholder="work With" />
                </Grid>
              </GridContainer>
            </Form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        <Button onClick={save} color="primary">
          Save
        </Button>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

NewPlan.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleOk: PropTypes.func,
  plan: PropTypes.object,
  userId: PropTypes.string,
  users: PropTypes.array
};

export default NewPlan;
