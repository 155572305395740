import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageContainer from '../../components/PageComponents/layouts/PageContainer';
import List from './List';
import { getAllUsers } from '../../redux/actions/users';

const breadcrumbs = [
  { label: 'Users', link: '/users' },
  { label: 'E-Plan', isActive: true }
];

const Plans = (props) => {
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.users);

  useEffect(() => {
    if (users.length <= 0) {
      dispatch(getAllUsers());
    }
  }, []);

  return (
    <PageContainer heading={`E-Plan`.toUpperCase()} breadcrumbs={breadcrumbs}>
      <List />
    </PageContainer>
  );
};

export default Plans;
