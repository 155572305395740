import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Select, FormControl, InputLabel, MenuItem } from '@material-ui/core';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import GridContainer from '../../../components/GridContainer';

import DateFnsUtils from '@date-io/date-fns';
import '../../../components/CommonFilter/filter.scss';

const Filter = ({ onFilter, initialFormState}) => {
  const formRef = useRef();
  const validationSchema = Yup.object().shape({
    startDate: Yup.string().required('This is Required Field'),
    endDate: Yup.string().required('This is Required Field'),
    status: Yup.string()
  });

  const submitForm = (values) => {
    onFilter(values);
  };

  const save = () => {
    formRef.current.submitForm();
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={{ ...initialFormState }}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={(values) => submitForm(values)}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <GridContainer className="commonFilter">
            <div>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Status</InputLabel>
                <Select
                  value={values.status}
                  label="Project"
                  onChange={({ target: { value } }) => setFieldValue('status', value)}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={false}>InActive</MenuItem>
                  <MenuItem value={true}>Active</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  label="From Date"
                  inputVariant="outlined"
                  value={values.startDate}
                  onChange={(e) => setFieldValue('startDate', e)}
                  style={{ width: '100%' }}
                  clearable={false}
                  format="MMM-dd-yyyy"
                  maxDate={values.endDate}
                  showTodayButton
                  autoOk
                />
              </MuiPickersUtilsProvider>
            </div>
            <div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  label="To Date"
                  inputVariant="outlined"
                  value={values.endDate}
                  onChange={(e) => setFieldValue('endDate', e)}
                  style={{ width: '100%' }}
                  clearable={false}
                  format="MMM-dd-yyyy"
                  minDate={values.startDate}
                  showTodayButton
                  autoOk
                />
              </MuiPickersUtilsProvider>
            </div>
            <div>
              <Button onClick={save} color="primary" variant="contained">
                Filter
              </Button>
            </div>
          </GridContainer>
        </Form>
      )}
    </Formik>
  );
};

Filter.propTypes = {
  onFilter: PropTypes.func,
  initialFormState: PropTypes.object,
};

export default Filter;
