import { loginService, getUser, setUser, removeUser } from '../../services/auth';
import { SET_USER, UPDATE_LOAD_USER, UPDATE_AUTH_USER, LOGOUT } from '../constants/auth';
import { history } from '../store';

export const login = (reqData, cb) => {
  return async (dispatch) => {
    try {
      const res = await loginService(reqData);
      const { data } = res;
      dispatch({ type: SET_USER, payload: data });
      setUser(data);
      cb();
    } catch (err) {}
  };
};

export const setAuthUser = () => {
  return (dispatch) => {
    dispatch({ type: UPDATE_LOAD_USER, payload: false });
    setTimeout(() => {
      dispatch({ type: UPDATE_AUTH_USER, payload: getUser() });
    }, 200);
  };
};

export const logout = () => {
  return (dispatch) => {
    dispatch({ type: LOGOUT });
    removeUser();
    history.push('/auth/login');
  };
};
