import {
  create,
  getAll,
  getOne,
  remove,
  update,
  getUserLogs,
  GetMechanicNeeds,
  getDriverPlans,
  addDriverPlan,
  updateDriverPlan,
  deleteDriverPlan
} from '../../services/user';
import {
  CREATE_USER,
  REMOVE_USER,
  SET_ALL_USERS,
  UPDATE_USER,
  SET_USER_LOGS,
  SET_USER_MECHANIC_NEEDS,
  SET_USER_PLANS,
} from '../constants/users';
import { setSuccessMessage } from './common';

export const getAllUsers = () => {
  return async (dispatch) => {
    try {
      const res = await getAll();
      const { data } = res;
      dispatch({
        type: SET_ALL_USERS,
        payload: data
      });
    } catch (err) { }
  };
};

export const createUser = (user, cb) => {
  return async (dispatch, getState) => {
    try {
      const users = [...getState()?.users?.users];
      const res = await create(user);
      const { data } = res;
      users.push(data);
      dispatch({
        type: CREATE_USER,
        payload: users
      });
      dispatch(setSuccessMessage('User created successfully'));
      cb();
    } catch (err) { }
  };
};

export const updateUser = (id, user, cb) => {
  return async (dispatch, getState) => {
    try {
      const users = [...getState()?.users?.users];
      const res = await update(id, user);
      const { data } = res;
      const index = users.findIndex((u) => u.id === id);
      users[index] = data;
      dispatch({
        type: UPDATE_USER,
        payload: users
      });
      dispatch(setSuccessMessage('User updated successfully'));
      cb();
    } catch (err) { }
  };
};

export const removeUser = (id) => {
  return async (dispatch, getState) => {
    try {
      const users = [...getState()?.users?.users];
      await remove(id);
      const index = users.findIndex((u) => u.id === id);
      users.splice(index, 1);
      dispatch({
        type: REMOVE_USER,
        payload: users
      });
      dispatch(setSuccessMessage('User removed successfully'));
    } catch (err) { }
  };
};

export const getOneUser = (id, cb) => {
  return async () => {
    try {
      const res = await getOne(id);
      const { data } = res;
      cb?.(data);
    } catch (err) {
      cb?.();
    }
  };
};

export const getAllUserLogs = (reqData) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: SET_USER_LOGS,
        payload: null
      });
      const res = await getUserLogs(reqData);
      const { data } = res;
      dispatch({
        type: SET_USER_LOGS,
        payload: data
      });
    } catch (err) { }
  };
};

export const getAllUserMechanicNeeds = (reqData) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: SET_USER_MECHANIC_NEEDS,
        payload: null
      });
      const res = await GetMechanicNeeds(reqData);
      const { data } = res;
      dispatch({
        type: SET_USER_MECHANIC_NEEDS,
        payload: data
      });
    } catch (err) { }
  };
};

export const getAllPlan = (reqData) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: SET_USER_PLANS,
        payload: null
      });
      const res = await getDriverPlans(reqData);
      const { data } = res;
      dispatch({
        type: SET_USER_PLANS,
        payload: data
      });
    } catch (err) { }
  };
};

export const addPlan = (reqData, cb) => {
  return async (dispatch, getState) => {
    try {
      await Promise.all(
        reqData.userIds.map(async (id) => {
          const data ={...reqData, userId: id};
          delete data.userIds;
          await addDriverPlan(data);
        })
      );
      cb();
    } catch (err) { }
  };
};

export const updatePlan = (id, reqData, cb) => {
  return async () => {
    try {
      await updateDriverPlan(id, reqData);
      cb();
    } catch (err) { }
  };
};

export const deletePlan = (id, cb) => {
  return async () => {
    try {
      await deleteDriverPlan(id);
      cb();
    } catch (err) { }
  };
};
