import axios from './axios-config';

export const getAll = () => axios.get('/user');

export const getOne = (id) => axios.get(`/user/${id}`);

export const create = (user) => axios.post('/user', user);

export const update = (id, user) => axios.put(`/user/${id}`, user);

export const remove = (id) => axios.delete(`/user/${id}`);

export const getUserLogs = (reqData) => axios.post('/daily-logs/with-pagination', reqData);

export const GetMechanicNeeds = (reqData) => axios.post('/mechanic-needs/with-pagination', reqData);

export const getDriverPlans = (reqData) => axios.post('/driver-plans/with-pagination', reqData);

export const deleteDriverPlan = (id) => axios.delete(`/driver-plans/${id}`);

export const addDriverPlan = (driverPlan) => axios.post('/driver-plans', driverPlan);

export const updateDriverPlan = (id, driverPlan) => axios.put(`/driver-plans/${id}`, driverPlan);
