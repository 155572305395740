export const SET_ALL_PROJECTS = 'SET_ALL_PROJECTS';
export const CREATE_PROJECT = 'CREATE_PROJECT';
export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const DELETE_PROJECT = 'DELETE_PROJECT';

export const SET_ALL_PROJECTS_PAIGINATION = 'SET_ALL_PROJECTS_PAIGINATION';
export const CREATE_PROJECT_PAIGINATION = 'CREATE_PROJECT_PAIGINATION';
export const UPDATE_PROJECT_PAIGINATION = 'UPDATE_PROJECT_PAIGINATION';
export const DELETE_PROJECT_PAIGINATION = 'DELETE_PROJECT_PAIGINATION';

export const SET_ALL_DRAWINGS = 'SET_ALL_DRAWINGS';
export const CREATE_DRAWING = 'CREATE_DRAWING';
export const DELETE_DRAWING = 'DELETE_DRAWING';

export const SET_ALL_MEDIA = 'SET_ALL_MEDIA';
