import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardContent } from '@material-ui/core';
import { Visibility } from '@material-ui/icons';
import { getAllMediasForProject } from '../../../redux/actions/project';

import '../../Drawings/List/drawingList.scss';

const { REACT_APP_API: url } = process.env;

const List = ({ projectId }) => {
  const dispatch = useDispatch();
  const { media } = useSelector(({ projects }) => projects);

  useEffect(() => {
    dispatch(getAllMediasForProject(projectId));
  }, []);

  const onView = (id) => {
    window.open(`${url}media/${id}`, '_blank');
  };

  return (
    <Card className="drawinglist">
      <CardContent>
        <div className="drawinglist__drawing">
          {media?.map((drawing) => (
            <div className="drawinglist__drawing-item" key={drawing.id}>
              <span>{drawing.name}</span>
              <Visibility onClick={() => onView(drawing.path)} />
            </div>
          ))}
          {media?.length === 0 && <span>No media found</span>}
        </div>
      </CardContent>

    </Card>
  );
};

List.propTypes = {
  projectId: PropTypes.number.isRequired
};

export default List;
