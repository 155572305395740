import React, { useEffect } from 'react';
import { Card, CardContent, TablePagination } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import MUIDataTable from 'mui-datatables';
import { format } from 'date-fns';
import { getAllUserLogs } from '../../../redux/actions/users';
import CustomeSearch from '../../../components/CustomSearch';
import PropTypes from 'prop-types';

const LogsList = ({ name }) => {
  const { userId } = useParams();

  const dispatch = useDispatch();
  const { userLogs } = useSelector(({ users }) => users);

  const columns = [
    {
      name: 'id',
      label: 'Name',
      options: {
        customBodyRender: () => {
          return name;
        },
        display: false
      }
    },
    {
      name: 'date',
      label: 'Date',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return format(new Date(value), 'MMM dd, yyyy');
        }
      }
    },
    {
      name: 'hoursWorked',
      label: 'Hours Worked'
    },
    {
      name: 'notes',
      label: 'Notes'
    },
    {
      name: 'project',
      label: 'Project',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value?.name;
        },
        sortCompare: (order) => {
          return (obj1, obj2) => {
            const val1 = parseInt(obj1.data?.id, 10);
            const val2 = parseInt(obj2.data?.id, 10);
            return (val1 - val2) * (order === 'asc' ? 1 : -1);
          };
        },
        filterOptions: {
          logic: (location, filters, row) => {
            if (filters.length) {
              return filters.some((filter) => !location.includes(filter));
            }
            return false;
          }
        }
      }
    }
  ];

  useEffect(() => {
    dispatch(getAllUserLogs({ userId, page: 1, pageSize: 100 }));
  }, []);

  const renderCustomSearch = (searchText, handleSearch, hideSearch, options) => (
    <CustomeSearch handleSearch={handleSearch} hideSearch={hideSearch} />
  );

  const pageChange = (page) => {
    dispatch(getAllUserLogs({ userId, page, pageSize: userLogs?.pageSize }));
  };

  const pageSizeChange = (pageSize) => {
    dispatch(getAllUserLogs({ userId, page: userLogs?.page, pageSize }));
  };

  const renderFooter = () => {
    return (
      <tbody>
        <tr>
          <TablePagination
            rowsPerPage={userLogs?.pageSize || 100}
            count={userLogs?.count}
            page={userLogs?.page ? userLogs.page - 1 : 1}
            onPageChange={(event, page) => pageChange(page + 1)}
            onRowsPerPageChange={(event) => pageSizeChange(event.target.value)}
            rowsPerPageOptions={[10, 20, 40, 80, 100]}
          />
        </tr>
      </tbody>
    );
  };

  return (
    <Card className="userlist">
      <CardContent>
        <MUIDataTable
          title="User Logs"
          data={userLogs?.data || []}
          columns={columns}
          options={{
            filter: false,
            responsive: 'standard',
            selectableRows: 'none',
            pagination: false,
            elevation: 0,
            rowsPerPageOptions: [10, 20, 40, 80, 100],
            customSearchRender: renderCustomSearch,
            customFooter: renderFooter,
            print: false,
            downloadOptions: {
              filename: `${name} logs.csv`
            },
            viewColumns: false,
            onDownload: (buildHead, buildBody, columns, data) => {
              const newData = data.map((item) => {
                return {
                  ...item,
                  data: [
                    name,
                    format(new Date(item.data[1]), 'MMM dd, yyyy'),
                    item.data[2],
                    item.data[3],
                    item.data[4]?.name
                  ]
                };
              });

              return buildHead(columns) + buildBody(newData);
            }
          }}
        />
      </CardContent>
    </Card>
  );
};

LogsList.propTypes = {
  name: PropTypes.string
};

export default LogsList;
