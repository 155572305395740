import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: 16
  }
}));
const DeleteDialog = ({ open, handleClose, handleOk, message }) => {
  const classes = useStyles();
  return (
    <Dialog maxWidth={'md'} fullWidth onClose={handleClose} open={open}>
      <DialogTitle className={classes.heading}>Confirmation</DialogTitle>
      <DialogContent dividers={true}>{message}</DialogContent>
      <DialogActions>
        <Button onClick={handleOk} color="primary">
          Yes
        </Button>
        <Button onClick={handleClose} color="primary">
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DeleteDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleOk: PropTypes.func,
  message: PropTypes.string
};
export default DeleteDialog;
