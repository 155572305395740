import React, { useEffect, useState } from 'react';
import { Card, CardContent, TablePagination } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import MUIDataTable from 'mui-datatables';
import { format } from 'date-fns';
import { getAllUserLogs } from '../../../redux/actions/users';
import CustomeSearch from '../../../components/CustomSearch';
import Filters from '../../../components/CommonFilter';
import { getAllProjects } from '../../../redux/actions/project';

const LogsList = () => {
  const dispatch = useDispatch();
  const { userLogs, users } = useSelector(({ users }) => users);
  const { projects } = useSelector(({ projects }) => projects);

  const [initialFormState, setInitialFormState] = useState({
    fromDate: new Date(),
    toDate: new Date(),
    userId: ''
  });

  const columns = [
    {
      name: 'user',
      label: 'Name',
      options: {
        customBodyRender: (value) => {
          return value?.firstname + ' ' + value?.lastname;
        },
        sortCompare: (order) => {
          return (obj1, obj2) => {
            const val1 = obj1.data?.firstname;
            const val2 = obj2.data?.firstname;
            // return (val1 - val2) * (order === 'asc' ? 1 : -1);
            return order === 'asc' ? val2.localeCompare(val1) : val1.localeCompare(val2);
          };
        }
      }
    },
    {
      name: 'date',
      label: 'Date',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return format(new Date(value), 'MMM dd, yyyy');
        }
      }
    },
    {
      name: 'hoursWorked',
      label: 'Hours Worked'
    },
    {
      name: 'notes',
      label: 'Notes'
    },
    {
      name: 'project',
      label: 'Project',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value?.name;
        },
        sortCompare: (order) => {
          return (obj1, obj2) => {
            const val1 = parseInt(obj1.data?.id, 10);
            const val2 = parseInt(obj2.data?.id, 10);
            return (val1 - val2) * (order === 'asc' ? 1 : -1);
          };
        },
        filterOptions: {
          logic: (location, filters, row) => {
            if (filters.length) {
              return filters.some((filter) => !location.includes(filter));
            }
            return false;
          }
        }
      }
    }
  ];

  useEffect(() => {
    const data = getFilterObj(initialFormState);
    dispatch(getAllUserLogs({ page: 1, pageSize: 100, ...data }));
    dispatch(getAllProjects())
  }, []);

  const renderCustomSearch = (searchText, handleSearch, hideSearch, options) => (
    <CustomeSearch handleSearch={handleSearch} hideSearch={hideSearch} />
  );

  const pageChange = (page) => {
    const data = getFilterObj(initialFormState);
    dispatch(getAllUserLogs({ page, pageSize: userLogs?.pageSize, ...data }));
  };

  const pageSizeChange = (pageSize) => {
    const data = getFilterObj(initialFormState);
    dispatch(getAllUserLogs({ page: userLogs?.page, pageSize, ...data }));
  };

  const renderFooter = () => {
    return (
      <tbody>
        <tr>
          <TablePagination
            rowsPerPage={userLogs?.pageSize || 100}
            count={userLogs?.count || 0}
            page={userLogs?.page ? userLogs.page - 1 : 1}
            onPageChange={(event, page) => pageChange(page + 1)}
            onRowsPerPageChange={(event) => pageSizeChange(event.target.value)}
            rowsPerPageOptions={[10, 20, 40, 80, 100]}
          />
        </tr>
      </tbody>
    );
  };

  const filter = (values) => {
    setInitialFormState(values);
    const data = getFilterObj(values);
    dispatch(getAllUserLogs({ page: 1, pageSize: 100, ...data }));
  };

  const getFilterObj = (values) => {
    return {
      userId: values.userId,
      startDate: format(values.fromDate, 'yyyy-MM-dd'),
      endDate: format(values.toDate, 'yyyy-MM-dd'),
      projectId: values.projectId
    };
  };

  return (
    <Card className="userlist">
      <Filters
        users={users}
        onFilter={filter}
        initialFormState={initialFormState}
        setInitialFormState={setInitialFormState}
        projects={projects}
        isProject
      />
      <CardContent>
        <MUIDataTable
          title="Daily Logs"
          data={userLogs?.data || []}
          columns={columns}
          options={{
            pagination: false,
            filter: false,
            responsive: 'standard',
            selectableRows: 'none',
            elevation: 0,
            rowsPerPageOptions: [10, 20, 40, 80, 100],
            customSearchRender: renderCustomSearch,
            customFooter: renderFooter,
            print: false,
            downloadOptions: {
              filename: `Daily logs.csv`
            },
            viewColumns: false,
            onDownload: (buildHead, buildBody, columns, data) => {
              const newData = data.map((item) => {
                return {
                  ...item,
                  data: [
                    item.data[0].firstname + ' ' + item.data[0].lastname,
                    format(new Date(item.data[1]), 'MMM dd, yyyy'),
                    item.data[2],
                    item.data[3],
                    item.data[4]?.name
                  ]
                };
              });
              return buildHead(columns) + buildBody(newData);
            }
          }}
        />
      </CardContent>
    </Card>
  );
};

LogsList.propTypes = {};

export default LogsList;
