import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardContent } from '@material-ui/core';
import { Delete, Visibility } from '@material-ui/icons';
import DeleteDialog from '../../../components/DeleteDialog';
import { deleteDrawing, getAllDrawingsForProject } from '../../../redux/actions/project';
import NewDrawing from '../NewDrawing';

import './drawingList.scss';

const { REACT_APP_API: url } = process.env;

const List = ({ projectId }) => {
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [selectedId, setSelectedId] = useState('');

  const [isNewOpen, setIsNewOpen] = useState(false);

  const dispatch = useDispatch();
  const { drawings } = useSelector(({ projects }) => projects);

  const onDelete = (id) => {
    setIsDeleteOpen(true);
    setSelectedId(id);
  };

  const handleDeleteConfirm = () => {
    dispatch(deleteDrawing(selectedId));
    setSelectedId('');
    setIsDeleteOpen(false);
  };

  useEffect(() => {
    dispatch(getAllDrawingsForProject(projectId));
  }, []);

  const onView = (id) => {
    window.open(`${url}drawing/${id}`, '_blank');
  };

  return (
    <Card className="drawinglist">
      <CardContent>
        <div className="drawinglist__header">
          <Button size="medium" color="primary" variant="contained" onClick={() => setIsNewOpen(true)}>
            New Drawing
          </Button>
        </div>
        <div className="drawinglist__drawing">
          {drawings?.map((drawing) => (
            <div className="drawinglist__drawing-item" key={drawing.id}>
              <span>{drawing.name}</span>
              <Visibility onClick={() => onView(drawing.path)} />
              <Delete style={{ color: 'red', cursor: 'pointer' }} onClick={() => onDelete(drawing.id)} />
            </div>
          ))}
          {drawings?.length === 0 && <span>No drawings found</span>}
        </div>
      </CardContent>

      <DeleteDialog
        open={isDeleteOpen}
        handleClose={() => {
          setIsDeleteOpen(false);
          setSelectedId('');
        }}
        handleOk={handleDeleteConfirm}
        message={'Are you sure you want to delete Drawing ?'}
      />
      {isNewOpen && (
        <NewDrawing
          open={isNewOpen}
          projectId={projectId}
          handleClose={() => {
            setIsNewOpen(false);
          }}
          handleOk={() => {
            setIsNewOpen(false);
          }}
        />
      )}
    </Card>
  );
};

List.propTypes = {
  projectId: PropTypes.number.isRequired
};

export default List;
