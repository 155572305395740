import React, { useState } from 'react';
import { Card, CardContent, Button, Grid, InputAdornment } from '@material-ui/core';
import { Lock, Person } from '@material-ui/icons';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import GridContainer from '../../components/GridContainer';
import TextField from '../../components/FormikWrappers/TextfieldWrapper';
import { login } from '../../redux/actions/auth';
import { useHistory } from 'react-router-dom';
import './login.scss';

const Login = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const validationSchema = Yup.object().shape({
    username: Yup.string().required('This is Required Field'),
    password: Yup.string().required('This is Required Field')
  });

  const [initialFormState] = useState({
    mobile: '',
    password: ''
  });

  const submit = (values) => {
    const reqData = {
      username: values.username,
      password: values.password,
      platform: 'web'
    };
    dispatch(
      login(reqData, () => {
        history.push('/users');
      })
    );
  };

  return (
    <div className="login">
      <Card className="login__card">
        <CardContent>
          <h1 className="login__card-title">Login</h1>

          <Formik
            initialValues={{ ...initialFormState }}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={(values) => submit(values)}
          >
            {() => (
              <Form>
                <GridContainer className="login__container">
                  <Grid item xs={12}>
                    <TextField
                      autoComplete="off"
                      name="username"
                      label="Username"
                      placeholder="Username"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Person />
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      name="password"
                      type="password"
                      label="Password"
                      placeholder="Password"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Lock />
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                </GridContainer>
                <GridContainer className="login__action">
                  <Button size="medium" color="primary" variant="contained" type="submit">
                    Login
                  </Button>
                </GridContainer>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </div>
  );
};

Login.propTypes = {};

export default Login;
