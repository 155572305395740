import React, { Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import PageLoader from '../components/PageLoader';
import ContentLoader from '../components/ContentLoader';
import NotFound from './NotFound';
import Login from './Login';
import Layout from '../components/AppLayout';
import PrivacyPolicy from './PrivacyPolicy';

const Routes = () => {
  return (
    <>
      <Suspense fallback={<PageLoader />}>
        <Switch>
          <Route path="/auth/login" component={Login} exact />
          <Route path="/404" component={NotFound} exact />
          <Route path="/privacy-policy" component={PrivacyPolicy} exact />
          <RestrictedRoute path="/" component={Layout} />
          <Redirect to="/404" />
        </Switch>
      </Suspense>
      <ContentLoader />
    </>
  );
};

// eslint-disable-next-line react/prop-types
const RestrictedRoute = ({ component: Component, ...rest }) => {
  const { authUser } = useSelector(({ auth }) => auth);
  return (
    <Route
      {...rest}
      render={(props) =>
        authUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/auth/login',
              // eslint-disable-next-line react/prop-types
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

export default Routes;
