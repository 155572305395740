import React from 'react';
import PageContainer from '../../components/PageComponents/layouts/PageContainer';
import List from './List';

const breadcrumbs = [{ label: 'Projects', isActive: true }];

const Projects = (props) => {
  return (
    <PageContainer heading={`Project`.toUpperCase()} breadcrumbs={breadcrumbs}>
      <List />
    </PageContainer>
  );
};

Projects.propTypes = {};

export default Projects;
