import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { insertDrawings } from '../../../redux/actions/project';
import { useDispatch } from 'react-redux';

import './drwaing.scss';
const NewDrawing = ({ projectId, open, handleClose, handleOk }) => {
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);

  const save = () => {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('file', file);
    });
    dispatch(
      insertDrawings(projectId, formData, () => {
        handleOk();
      })
    );
  };

  const onFileSelect = (e) => {
    const allFiles = [...files, ...e];
    setFiles(allFiles);
  };

  const remove = (index) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  return (
    <Dialog maxWidth={'md'} fullWidth onClose={handleClose} open={open}>
      <DialogTitle className="">{'New Drawing'}</DialogTitle>
      <DialogContent dividers={true}>
        <div className="drawing">
          {files.map((file, i) => (
            <div className="drawing__pic" key={i} onClick={() => remove(i)}>
              <span>{file.name}</span>
            </div>
          ))}
          <label className="drawing__pic" htmlFor="fileinput">
            <span>Add Drawings</span>
          </label>
          <input id="fileinput" type="file" multiple onChange={(e) => onFileSelect(e.target.files)} />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={save} color="primary">
          Save
        </Button>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

NewDrawing.propTypes = {
  projectId: PropTypes.number.isRequired,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleOk: PropTypes.func
};

export default NewDrawing;
