import produce from 'immer';
import {
  CREATE_USER,
  REMOVE_USER,
  SET_ALL_USERS,
  UPDATE_USER,
  SET_USER_LOGS,
  SET_USER_MECHANIC_NEEDS,
  SET_USER_PLANS
} from '../constants/users';
import { LOGOUT } from '../constants/auth';
const INIT_STATE = {
  users: [],
  userLogs: null,
  userMechanicNeeds: null,
  userPlans: null
};

export default produce((draft, action) => {
  switch (action.type) {
    case CREATE_USER:
    case UPDATE_USER:
    case REMOVE_USER:
    case SET_ALL_USERS:
      draft.users = action.payload;
      break;
    case SET_USER_LOGS:
      draft.userLogs = action.payload;
      break;
    case SET_USER_MECHANIC_NEEDS:
      draft.userMechanicNeeds = action.payload;
      break;
    case SET_USER_PLANS:
      draft.userPlans = action.payload;
      break;
    case LOGOUT:
      return INIT_STATE;
    default:
      return draft;
  }
}, INIT_STATE);
