import React, { useEffect, useState } from 'react';
import { Button, Card, CardContent, TablePagination } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import MUIDataTable from 'mui-datatables';
import CustomeSearch from '../../../components/CustomSearch';
import { getAllProjectsWithPagination, removeProject } from '../../../redux/actions/project';
import { Delete, Edit, PictureAsPdf,PermMedia } from '@material-ui/icons';
import DeleteDialog from '../../../components/DeleteDialog';
import NewProject from '../NewProject';
import Filter from '../Filter/index';

import './projectlist.scss';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';

const ProjectList = () => {
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [selectedId, setSelectedId] = useState('');

  const [isNewOpen, setIsNewOpen] = useState(false);
  const [project, setProject] = useState(null);

  const dispatch = useDispatch();
  const history = useHistory();
  const { allProjects } = useSelector(({ projects }) => projects);


  const [initialFormState, setInitialFormState] = useState({
    startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
    endDate: new Date(),
    status: ""
  });

  useEffect(() => {
    const data = getFilterObj(initialFormState);
    dispatch(getAllProjectsWithPagination({ ...data, page: 1, pageSize: 100 }));
  }, []);

  const getFilterObj = (values) => {
    return {
      status: values.status === "" ? null : values.status,
      startDate: format(values.startDate, 'yyyy-MM-dd'),
      endDate: format(values.endDate, 'yyyy-MM-dd')
    };
  };

  const pageChange = (page) => {
    const data = getFilterObj(initialFormState);
    dispatch(getAllProjectsWithPagination({ page, pageSize: allProjects?.pageSize, ...data }));
  };

  const pageSizeChange = (pageSize) => {
    const data = getFilterObj(initialFormState);
    dispatch(getAllProjectsWithPagination({ page: allProjects?.page, pageSize, ...data }));
  };

  const columns = [
    {
      name: 'name',
      label: 'Project Name'
    },
    {
      name: 'description',
      label: 'Description'
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? 'Active' : 'Inactive';
        }
      }
    },
    {
      name: 'id',
      label: 'Actions',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Edit style={{ cursor: 'pointer' }} color="primary" onClick={() => editClickHandler(value)} />
              <Delete style={{ color: 'red', cursor: 'pointer' }} onClick={() => onDelete(value)} />
              <PictureAsPdf style={{ cursor: 'pointer' }} color="primary" onClick={() => uploadPdf(value)} />
              <PermMedia style={{ cursor: 'pointer' }} color="primary" onClick={() => history.push(`/projects/media/${value}`)} />
            </>
          );
        }
      }
    }
  ];

  const uploadPdf = (id) => {
    history.push(`/projects/drawings/${id}`);
  };

  const editClickHandler = (id) => {
    const editable = allProjects?.data.find((project) => project.id === id);
    setProject(editable);
    setIsNewOpen(true);
  };

  const onDelete = (id) => {
    setIsDeleteOpen(true);
    setSelectedId(id);
  };

  const handleDeleteConfirm = () => {
    dispatch(removeProject(selectedId));
    setSelectedId('');
    setIsDeleteOpen(false);
  };

  const renderCustomSearch = (searchText, handleSearch, hideSearch, options) => (
    <CustomeSearch handleSearch={handleSearch} hideSearch={hideSearch} />
  );

  const renderFooter = () => {
    return (
      <tbody>
        <tr>
          <TablePagination
            rowsPerPage={allProjects?.pageSize || 100}
            count={allProjects?.count || 0}
            page={allProjects?.page ? allProjects.page - 1 : 1}
            onPageChange={(event, page) => pageChange(page + 1)}
            onRowsPerPageChange={(event) => pageSizeChange(event.target.value)}
            rowsPerPageOptions={[10, 20, 40, 80, 100]}
          />
        </tr>
      </tbody>
    );
  };

  const filter = (values) => {
    setInitialFormState(values);
    const data = getFilterObj(values);
    dispatch(getAllProjectsWithPagination({ page: 1, pageSize: 100, ...data }));
  };

  return (
    <Card className="userlist">
      <Filter
        onFilter={filter}
        initialFormState={initialFormState}
      />
      <CardContent>
        <div className="userlist__header">
          <Button size="medium" color="primary" variant="contained" onClick={() => setIsNewOpen(true)}>
            New Project
          </Button>
        </div>
        <MUIDataTable
          title="Projects"
          data={allProjects?.data || []}
          columns={columns}
          options={{
            filterType: 'textField',
            responsive: 'standard',
            selectableRows: 'none',
            pagination: false,
            elevation: 0,
            rowsPerPageOptions: [10, 20, 40, 80, 100],
            customSearchRender: renderCustomSearch,
            customFooter: renderFooter,
            print: false,
            viewColumns: false,
          }}
        />
      </CardContent>

      <DeleteDialog
        open={isDeleteOpen}
        handleClose={() => {
          setIsDeleteOpen(false);
          setSelectedId('');
        }}
        handleOk={handleDeleteConfirm}
        message={'Are you sure you want to delete Project ?'}
      />
      {isNewOpen && (
        <NewProject
          open={isNewOpen}
          project={project}
          handleClose={() => {
            setIsNewOpen(false);
            setProject(null);
          }}
          handleOk={() => {
            setIsNewOpen(false);
            setProject(null);
          }}
        />
      )}
    </Card>
  );
};

ProjectList.propTypes = {};

export default ProjectList;
