import React, { useEffect } from 'react';
import { Card, CardContent, TablePagination } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import MUIDataTable from 'mui-datatables';
import { format } from 'date-fns';
import { getAllUserMechanicNeeds } from '../../../redux/actions/users';
import CustomeSearch from '../../../components/CustomSearch';
import PropTypes from 'prop-types';

const NeedsList = ({ name }) => {
  const { userId } = useParams();

  const dispatch = useDispatch();
  const { userMechanicNeeds } = useSelector(({ users }) => users);

  const columns = [
    {
      name: 'id',
      label: 'Name',
      options: {
        customBodyRender: () => {
          return name;
        },
        display: false
      }
    },
    {
      name: 'date',
      label: 'Date',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return format(new Date(value), 'MMM dd, yyyy');
        }
      }
    },
    {
      name: 'itemNeeded',
      label: 'Item Needed'
    },
    {
      name: 'needBy',
      label: 'Need By',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return format(new Date(value), 'MMM dd, yyyy');
        }
      }
    },
    {
      name: 'project',
      label: 'Project',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value.name;
        },
        sortCompare: (order) => {
          return (obj1, obj2) => {
            const val1 = parseInt(obj1.data?.id, 10);
            const val2 = parseInt(obj2.data?.id, 10);
            return (val1 - val2) * (order === 'asc' ? 1 : -1);
          };
        },
        filterOptions: {
          logic: (location, filters, row) => {
            if (filters.length) {
              return filters.some((filter) => !location.includes(filter));
            }
            return false;
          }
        }
      }
    }
  ];

  useEffect(() => {
    dispatch(getAllUserMechanicNeeds({ userId, page: 1, pageSize: 100 }));
  }, []);

  const renderCustomSearch = (searchText, handleSearch, hideSearch, options) => (
    <CustomeSearch handleSearch={handleSearch} hideSearch={hideSearch} />
  );

  const pageChange = (page) => {
    dispatch(getAllUserMechanicNeeds({ userId, page, pageSize: userMechanicNeeds?.pageSize }));
  };

  const pageSizeChange = (pageSize) => {
    dispatch(getAllUserMechanicNeeds({ userId, page: userMechanicNeeds?.page, pageSize }));
  };

  const renderFooter = () => {
    return (
      <tbody>
        <tr>
          <TablePagination
            rowsPerPage={userMechanicNeeds?.pageSize || 100}
            count={userMechanicNeeds?.count}
            page={userMechanicNeeds?.page ? userMechanicNeeds.page - 1 : 1}
            onPageChange={(event, page) => pageChange(page + 1)}
            onRowsPerPageChange={(event) => pageSizeChange(event.target.value)}
            rowsPerPageOptions={[10, 20, 40, 80, 100]}
          />
        </tr>
      </tbody>
    );
  };

  return (
    <Card className="">
      <CardContent>
        <MUIDataTable
          title="Mechanic Need"
          data={userMechanicNeeds?.data || []}
          columns={columns}
          options={{
            filter: false,
            responsive: 'standard',
            selectableRows: 'none',
            pagination: false,
            elevation: 0,
            rowsPerPageOptions: [10, 20, 40, 80, 100],
            customSearchRender: renderCustomSearch,
            customFooter: renderFooter,
            print: false,
            downloadOptions: {
              filename: `${name}  Needs.csv`
            },
            viewColumns: false,
            onDownload: (buildHead, buildBody, columns, values) => {
              const newData = values.map(({ index, data }) => {
                const [, date, need, needBy, project] = data;
                return {
                  index,
                  data: [
                    name,
                    format(new Date(date), 'MMM dd, yyyy'),
                    need,
                    format(new Date(needBy), 'MMM dd, yyyy'),
                    project?.name
                  ]
                };
              });

              return buildHead(columns) + buildBody(newData);
            }
          }}
        />
      </CardContent>
    </Card>
  );
};

NeedsList.propTypes = {
  name: PropTypes.string
};

export default NeedsList;
