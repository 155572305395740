import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageContainer from '../../components/PageComponents/layouts/PageContainer';
import List from './List';
import { getOneUser } from '../../redux/actions/users';
import { useParams, useHistory } from 'react-router-dom';

const breadcrumbs = [
  { label: 'Users', link: '/users' },
  { label: 'E-Plan', isActive: true }
];

const DrivarPlans = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { users } = useSelector((state) => state.users);
  const { userId } = useParams();
  const [user, setUser] = useState(null);
  useEffect(() => {
    if (users.length > 0) {
      const item = users.find((u) => u.id === Number(userId));
      if (item) {
        setUser(item);
      } else {
        getOne();
      }
    } else {
      getOne();
    }
  }, []);

  const getOne = () => {
    dispatch(
      getOneUser(userId, (item) => {
        if (!item) {
          history.goBack();
        }
        setUser(item);
      })
    );
  };

  return (
    <PageContainer
      heading={`E-Plan of ${user?.firstname} ${user?.lastname}`.toUpperCase()}
      breadcrumbs={breadcrumbs}
    >
      <List name={`${user?.firstname || ''} ${user?.lastname || ''}`} />
    </PageContainer>
  );
};

DrivarPlans.propTypes = {};

export default DrivarPlans;
