import axios from './axios-config';

export const getAll = () => axios.get('/project');

export const getOne = (id) => axios.get(`/project/${id}`);

export const create = (project) => axios.post('/project', project);

export const update = (id, project) => axios.put(`/project/${id}`, project);

export const remove = (id) => axios.delete(`/project/${id}`);

export const addDrawing = (id, drawing) => axios.post(`/drawing/multiple/${id}`, drawing);

export const getAllDrawing = (id) => axios.get(`/drawing/project/${id}`);

export const getOneDrawing = (id) => axios.get(`/drawing/${id}`);

export const deleteOneDrawing = (id) => axios.delete(`/drawing/${id}`);

export const getAllProjects = (reqData) => axios.post('/project/with-pagination', reqData);

export const getAllMedia = (id) => axios.get(`/media/project/${id}`);
