import React, { useEffect, useState } from 'react';
import { Button, Card, CardContent, TablePagination } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import MUIDataTable from 'mui-datatables';
import { format } from 'date-fns';
import { getAllPlan, deletePlan } from '../../../redux/actions/users';
import CustomeSearch from '../../../components/CustomSearch';
import { Delete, Edit } from '@material-ui/icons';
import DeleteDialog from '../../../components/DeleteDialog';
import NewPlan from '../NewPlan';
import Filters from '../../../components/CommonFilter';

const List = () => {
  const { userId } = useParams();
  const dispatch = useDispatch();
  const { userPlans, users } = useSelector(({ users }) => users);

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [selectedId, setSelectedId] = useState('');

  const [isNewOpen, setIsNewOpen] = useState(false);
  const [plan, setPlan] = useState(null);

  const [initialFormState, setInitialFormState] = useState({
    fromDate: new Date(),
    toDate: new Date(),
    userId: ''
  });

  const columns = [
    {
      name: 'user',
      label: 'Name',
      options: {
        customBodyRender: (value) => {
          return value?.firstname + ' ' + value?.lastname;
        },
        sortCompare: (order) => {
          return (obj1, obj2) => {
            const val1 = obj1.data?.firstname;
            const val2 = obj2.data?.firstname;
            // return (val1 > val2) * (order === 'asc' ? 1 : -1);
            return order === 'asc' ? val2.localeCompare(val1) : val1.localeCompare(val2);
          };
        }
      }
    },
    {
      name: 'date',
      label: 'Date',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value) return format(new Date(value), 'MMM dd, yyyy');
          return '';
        }
      }
    },
    {
      name: 'time',
      label: 'Time',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const dateTime = new Date();
          if (value) {
            const [hh, mm] = value.split(':');
            dateTime.setHours(hh, mm, 0);
            return format(new Date(dateTime), 'hh:mm a');
          }
          return '';
        },
        sortCompare: (order) => {
          return (obj1, obj2) => {
            const [hh1, mm1] = obj1.data.split(':');
            const [hh2, mm2] = obj2.data.split(':');
            const val1 = hh1 * 60 + mm1;
            const val2 = hh2 * 60 + mm2;
            return order === 'asc' ? val2 - val1 : val1 - val2;
          };
        }
      }
    },
    {
      name: 'location',
      label: 'Location'
    },
    {
      name: 'company',
      label: 'Company'
    },
    {
      name: 'contact',
      label: 'Contact'
    },
    {
      name: 'phoneNo',
      label: 'Phone Number'
    },
    {
      name: 'dropOff',
      label: 'DropOff'
    },
    {
      name: 'pickup',
      label: 'PickUp'
    },
    {
      name: 'todo',
      label: 'To Do'
    },
    {
      name: 'workWith',
      label: 'work With'
    },
    {
      name: 'id',
      label: 'Action',
      options: {
        sort: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Edit style={{ cursor: 'pointer' }} color="primary" onClick={() => editClickHandler(value)} />
              <Delete style={{ color: 'red', cursor: 'pointer' }} onClick={() => onDelete(value)} />
            </>
          );
        }
      }
    }
  ];

  const editClickHandler = (id) => {
    const editable = userPlans.data.find((item) => item.id === id);
    setPlan(editable);
    setIsNewOpen(true);
  };

  const onDelete = (id) => {
    setIsDeleteOpen(true);
    setSelectedId(id);
  };

  const handleDeleteConfirm = () => {
    dispatch(
      deletePlan(selectedId, () => {
        dispatch(getAllPlan({ userId, page: 1, pageSize: 10 }));
      })
    );
    setSelectedId('');
    setIsDeleteOpen(false);
  };

  useEffect(() => {
    const data = getFilterObj(initialFormState);
    dispatch(getAllPlan({ page: 1, pageSize: 100, ...data }));
  }, []);

  const renderCustomSearch = (searchText, handleSearch, hideSearch, options) => (
    <CustomeSearch handleSearch={handleSearch} hideSearch={hideSearch} />
  );

  const pageChange = (page) => {
    const data = getFilterObj(initialFormState);
    dispatch(getAllPlan({ page, pageSize: userPlans?.pageSize, ...data }));
  };

  const pageSizeChange = (pageSize) => {
    const data = getFilterObj(initialFormState);
    dispatch(getAllPlan({ page: userPlans?.page, pageSize, ...data }));
  };

  const filter = (values) => {
    setInitialFormState(values);
    const data = getFilterObj(values);
    dispatch(getAllPlan({ page: 1, pageSize: 100, ...data }));
  };

  const getFilterObj = (values) => {
    return {
      userId: values.userId,
      startDate: format(values.fromDate, 'yyyy-MM-dd'),
      endDate: format(values.toDate, 'yyyy-MM-dd')
    };
  };

  const renderFooter = () => {
    return (
      <tbody>
        <tr>
          <TablePagination
            rowsPerPage={userPlans?.pageSize || 100}
            count={userPlans?.count || 0}
            page={userPlans?.page ? userPlans.page - 1 : 1}
            onPageChange={(event, page) => pageChange(page + 1)}
            onRowsPerPageChange={(event) => pageSizeChange(event.target.value)}
            rowsPerPageOptions={[10, 20, 40, 80, 100]}
          />
        </tr>
      </tbody>
    );
  };

  return (
    <Card className="planlist">
      <Filters
        users={users}
        onFilter={filter}
        initialFormState={initialFormState}
        setInitialFormState={setInitialFormState}
      />
      <CardContent>
        <div className="planlist__header">
          <Button size="medium" color="primary" variant="contained" onClick={() => setIsNewOpen(true)}>
            New Plan
          </Button>
        </div>
        <MUIDataTable
          title="E-Plan"
          data={userPlans?.data || []}
          columns={columns}
          options={{
            filter: false,
            responsive: 'standard',
            selectableRows: 'none',
            pagination: false,
            elevation: 0,
            rowsPerPageOptions: [10, 20, 40, 80, 100],
            customSearchRender: renderCustomSearch,
            customFooter: renderFooter,
            print: false,
            downloadOptions: {
              filename: `E-Plan.csv`
            },
            viewColumns: false,
            onDownload: (buildHead, buildBody, columns, values) => {
              const newData = values.map(({ index, data }) => {
                const [{ firstname, lastname }, date, time, ...rest] = data;
                const dateTime = new Date();
                if (time) {
                  const [hh, mm] = time.split(':');
                  dateTime.setHours(hh, mm, 0);
                }
                return {
                  index,
                  data: [
                    `${firstname} ${lastname}`,
                    format(new Date(date), 'MMM dd, yyyy'),
                    time ? format(new Date(dateTime), 'hh:mm a') : '',
                    ...rest
                  ]
                };
              });

              return buildHead(columns) + buildBody(newData);
            }
          }}
        />

        <DeleteDialog
          open={isDeleteOpen}
          handleClose={() => {
            setIsDeleteOpen(false);
            setSelectedId('');
          }}
          handleOk={handleDeleteConfirm}
          message={'Are you sure you want to delete E-Plan ?'}
        />
        {isNewOpen && (
          <NewPlan
            open={isNewOpen}
            plan={plan}
            userId={userId}
            users={users}
            handleClose={() => {
              setIsNewOpen(false);
              setPlan(null);
            }}
            handleOk={() => {
              const data = getFilterObj(initialFormState)
              setIsNewOpen(false);
              setPlan(null);
              dispatch(getAllPlan({ page: 1, pageSize: 10, ...data }));
            }}
          />
        )}
      </CardContent>
    </Card>
  );
};



export default List;
