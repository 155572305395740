import React from 'react';
import PageContainer from '../../components/PageComponents/layouts/PageContainer';
import List from './List';

const breadcrumbs = [{ label: 'Users', isActive: true }];

const User = (props) => {
  return (
    <PageContainer heading={`Users`.toUpperCase()} breadcrumbs={breadcrumbs}>
      <List />
    </PageContainer>
  );
};

User.propTypes = {};

export default User;
