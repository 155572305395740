import React from 'react';
import PropTypes from 'prop-types';
import { Grow, IconButton, TextField } from '@material-ui/core';
import { Search, Clear } from '@material-ui/icons';

const TableSearch = ({ handleSearch, hideSearch }) => {
  return (
    <Grow appear in={true} timeout={300}>
      <TextField
        variant="outlined"
        size="small"
        fullWidth
        onChange={({ target: { value } }) => handleSearch(value)}
        InputProps={{
          style: {
            paddingRight: 0
          },
          startAdornment: (
            <IconButton
              onClick={() => {
                hideSearch();
              }}
            >
              <Clear />
            </IconButton>
          ),
          endAdornment: (
            <IconButton>
              <Search />
            </IconButton>
          )
        }}
      />
    </Grow>
  );
};

TableSearch.propTypes = {
  handleSearch: PropTypes.func,
  hideSearch: PropTypes.func
};

export default TableSearch;
