import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import Common from './common';
import Auth from './auth';
import Users from './users';
import Projects from './project';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    common: Common,
    auth: Auth,
    users: Users,
    projects: Projects
  });
