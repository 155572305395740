import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Select, FormControl, InputLabel, MenuItem } from '@material-ui/core';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import GridContainer from '../GridContainer';

import DateFnsUtils from '@date-io/date-fns';
import './filter.scss';

const Filter = ({ users, onFilter, initialFormState, projects, isProject, setInitialFormState }) => {
  const formRef = useRef();
  const validationSchema = Yup.object().shape({
    fromDate: Yup.string().required('This is Required Field'),
    toDate: Yup.string().required('This is Required Field'),
    userId: Yup.string(),
    projectId: Yup.string()
  });

  const submitForm = (values) => {
    onFilter(values);
  };

  const save = () => {
    formRef.current.submitForm();
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={{ ...initialFormState }}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={(values) => submitForm(values)}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <GridContainer className="commonFilter">
            <div>
              <FormControl fullWidth variant="outlined">
                <InputLabel>User</InputLabel>
                <Select
                  value={values.userId}
                  label="User"
                  onChange={({ target: { value } }) => setFieldValue('userId', value)}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {users
                    .filter(({ role }) => role === 2)
                    .map((user) => (
                      <MenuItem value={user.id} key={user.id}>{`${user.firstname} ${user.lastname}`}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
            {isProject && (
              <div>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Project</InputLabel>
                  <Select
                    value={values.projectId}
                    label="Project"
                    onChange={({ target: { value } }) => setFieldValue('projectId', value)}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {projects
                      .map((item) => (
                        <MenuItem value={item.id} key={item.id}>{`${item.name}`}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
            )}
            <div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  label="From Date"
                  inputVariant="outlined"
                  value={values.fromDate}
                  onChange={(e) => setFieldValue('fromDate', e)}
                  style={{ width: '100%' }}
                  clearable={false}
                  format="MMM-dd-yyyy"
                  maxDate={values.toDate}
                  showTodayButton
                  autoOk
                />
              </MuiPickersUtilsProvider>
            </div>
            <div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  label="To Date"
                  inputVariant="outlined"
                  value={values.toDate}
                  onChange={(e) => setFieldValue('toDate', e)}
                  style={{ width: '100%' }}
                  clearable={false}
                  format="MMM-dd-yyyy"
                  minDate={values.fromDate}
                  showTodayButton
                  autoOk
                />
              </MuiPickersUtilsProvider>
            </div>
            <div>
              <Button onClick={save} color="primary" variant="contained">
                Filter
              </Button>
            </div>
          </GridContainer>
        </Form>
      )}
    </Formik>
  );
};

Filter.propTypes = {
  users: PropTypes.array,
  projects: PropTypes.array,
  onFilter: PropTypes.func,
  initialFormState: PropTypes.object,
  setInitialFormState: PropTypes.func,
  isProject: PropTypes.bool
};

export default Filter;
