import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { ExitToApp } from '@material-ui/icons';
import { Link, Switch, Route, Redirect } from 'react-router-dom';
import Users from '../../pages/Users';
import Projects from '../../pages/Projects';
import DailyLogs from '../../pages/Daliylogs';
import MechanicNeeds from '../../pages/MechanicNeeds';
import Drawings from '../../pages/Drawings';
import DriverPlans from '../../pages/DriverPlans';
import AllDriverLogs from '../../pages/AllDailyLogs';
import AllMechanicNeeds from '../../pages/AllMechanicNeeds';
import AllDriverPlans from '../../pages/AllDriverPlans';
import Media from '../../pages/Media';
import { logout } from '../../redux/actions/auth';

import './apployout.scss';
import { useDispatch } from 'react-redux';

export default function MenuAppBar() {
  const dispatch = useDispatch();

  return (
    <div className="layout">
      <AppBar position="static">
        <Toolbar>
          <IconButton edge="start" className="layout__menuButton">
            BSIS
          </IconButton>
          <div className="layout__menus">
            <Link to="/users">
              <Typography variant="h6">Users</Typography>
            </Link>
            <Link to="/projects">
              <Typography variant="h6">Projects</Typography>
            </Link>
            <Link to="/daily-logs">
              <Typography variant="h6">Log</Typography>
            </Link>
            <Link to="/mechanic-needs">
              <Typography variant="h6">Needs</Typography>
            </Link>
            <Link to="/driver-plans">
              <Typography variant="h6">Plans</Typography>
            </Link>
          </div>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            color="inherit"
            onClick={() => dispatch(logout())}
          >
            <ExitToApp />
          </IconButton>
        </Toolbar>
      </AppBar>

      <div className="layout__container">
        <Switch>
          <Route path="/users" exact component={Users} />
          <Route path="/projects" exact component={Projects} />
          <Route path="/daily-logs/:userId" exact component={DailyLogs} />
          <Route path="/mechanic-needs/:userId" exact component={MechanicNeeds} />
          <Route path="/projects/drawings/:projectId" exact component={Drawings} />
          <Route path="/projects/media/:projectId" exact component={Media} />
          <Route path="/driver-plans/:userId" exact component={DriverPlans} />
          <Route path="/daily-logs" exact component={AllDriverLogs} />
          <Route path="/mechanic-needs" exact component={AllMechanicNeeds} />
          <Route path="/driver-plans" exact component={AllDriverPlans} />
          <Route path="/" exact render={() => <Redirect to="/users" />} />
          <Redirect to="/404" />
        </Switch>
      </div>
    </div>
  );
}
