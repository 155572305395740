import produce from 'immer';
import {
  CREATE_PROJECT,
  DELETE_PROJECT,
  SET_ALL_PROJECTS,
  UPDATE_PROJECT,
  SET_ALL_DRAWINGS,
  DELETE_DRAWING,
  CREATE_DRAWING,
  CREATE_PROJECT_PAIGINATION,
  DELETE_PROJECT_PAIGINATION,
  UPDATE_PROJECT_PAIGINATION,
  SET_ALL_PROJECTS_PAIGINATION,
  SET_ALL_MEDIA
} from '../constants/project';
import { LOGOUT } from '../constants/auth';

const INIT_STATE = {
  projects: [],
  drawings: [],
  meida: [],
  allProjects: null
};

export default produce((draft, action) => {
  switch (action.type) {
    case CREATE_PROJECT:
    case DELETE_PROJECT:
    case UPDATE_PROJECT:
    case SET_ALL_PROJECTS:
      draft.projects = action.payload;
      break;
    case CREATE_PROJECT_PAIGINATION:
    case DELETE_PROJECT_PAIGINATION:
    case UPDATE_PROJECT_PAIGINATION:
    case SET_ALL_PROJECTS_PAIGINATION:
      draft.allProjects = action.payload;
      break;

    case CREATE_DRAWING:
    case DELETE_DRAWING:
    case SET_ALL_DRAWINGS:
      draft.drawings = action.payload;
      break;
    case SET_ALL_MEDIA:
      draft.media = action.payload;
      break;
    case LOGOUT:
      return INIT_STATE;

    default:
      return draft;
  }
}, INIT_STATE);
