import React, {
  useEffect,
  // useState 
} from 'react';
import {
  // Button,
  Card, CardContent, TablePagination
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import MUIDataTable from 'mui-datatables';
import { format } from 'date-fns';
import {
  getAllPlan,
  //  deletePlan
} from '../../../redux/actions/users';
import CustomeSearch from '../../../components/CustomSearch';
// import { Delete, Edit } from '@material-ui/icons';
// import DeleteDialog from '../../../components/DeleteDialog';
// import NewPlan from '../NewPlan';
import PropTypes from 'prop-types';
import './planlist.scss';

const List = ({ name }) => {
  const { userId } = useParams();
  const dispatch = useDispatch();
  const { userPlans } = useSelector(({ users }) => users);

  // const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  // const [selectedId, setSelectedId] = useState('');

  // const [isNewOpen, setIsNewOpen] = useState(false);
  // const [plan, setPlan] = useState(null);

  const columns = [
    {
      name: 'date',
      label: 'Date',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value) return format(new Date(value), 'MMM dd, yyyy');
          return '';
        }
      }
    },
    {
      name: 'time',
      label: 'Time',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const dateTime = new Date();
          if (value) {
            const [hh, mm] = value.split(':');
            dateTime.setHours(hh, mm, 0);
            return format(new Date(dateTime), 'hh:mm a');
          }
          return '';
        }
      }
    },
    {
      name: 'location',
      label: 'Location'
    },
    {
      name: 'company',
      label: 'Company'
    },
    {
      name: 'contact',
      label: 'Contact'
    },
    {
      name: 'phoneNo',
      label: 'Phone Number'
    },
    {
      name: 'dropOff',
      label: 'DropOff'
    },
    {
      name: 'pickup',
      label: 'PickUp'
    },
    {
      name: 'todo',
      label: 'To Do'
    },
    // {
    //   name: 'id',
    //   label: 'Action',
    //   options: {
    //     sort: false,
    //     download: false,
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       return (
    //         <>
    //           <Edit style={{ cursor: 'pointer' }} color="primary" onClick={() => editClickHandler(value)} />
    //           <Delete style={{ color: 'red', cursor: 'pointer' }} onClick={() => onDelete(value)} />
    //         </>
    //       );
    //     }
    //   }
    // }
  ];

  // const editClickHandler = (id) => {
  //   const editable = userPlans.data.find((item) => item.id === id);
  //   setPlan(editable);
  //   setIsNewOpen(true);
  // };

  // const onDelete = (id) => {
  //   setIsDeleteOpen(true);
  //   setSelectedId(id);
  // };

  // const handleDeleteConfirm = () => {
  //   dispatch(
  //     deletePlan(selectedId, () => {
  //       dispatch(getAllPlan({ userId, page: 1, pageSize: 10 }));
  //     })
  //   );
  //   setSelectedId('');
  //   setIsDeleteOpen(false);
  // };

  useEffect(() => {
    dispatch(getAllPlan({ userId, page: 1, pageSize: 100 }));
  }, []);

  const renderCustomSearch = (searchText, handleSearch, hideSearch, options) => (
    <CustomeSearch handleSearch={handleSearch} hideSearch={hideSearch} />
  );

  const pageChange = (page) => {
    dispatch(getAllPlan({ userId, page, pageSize: userPlans?.pageSize }));
  };

  const pageSizeChange = (pageSize) => {
    dispatch(getAllPlan({ userId, page: userPlans?.page, pageSize }));
  };

  const renderFooter = () => {
    return (
      <tbody>
        <tr>
          <TablePagination
            rowsPerPage={userPlans?.pageSize || 100}
            count={userPlans?.count}
            page={userPlans?.page ? userPlans.page - 1 : 1}
            onPageChange={(event, page) => pageChange(page + 1)}
            onRowsPerPageChange={(event) => pageSizeChange(event.target.value)}
            rowsPerPageOptions={[10, 20, 40, 80, 100]}
          />
        </tr>
      </tbody>
    );
  };

  return (
    <Card className="planlist">
      <CardContent>
        {/* <div className="planlist__header">
          <Button size="medium" color="primary" variant="contained" onClick={() => setIsNewOpen(true)}>
            New Plan
          </Button>
        </div> */}
        <MUIDataTable
          title="E-Plan"
          data={userPlans?.data || []}
          columns={columns}
          options={{
            filter: false,
            responsive: 'standard',
            selectableRows: 'none',
            pagination: false,
            elevation: 0,
            rowsPerPageOptions: [10, 20, 40, 80, 100],
            customSearchRender: renderCustomSearch,
            customFooter: renderFooter,
            print: false,
            downloadOptions: {
              filename: `${name}  Plan.csv`
            },
            viewColumns: false,
            onDownload: (buildHead, buildBody, columns, values) => {
              const newData = values.map(({ index, data }) => {
                const [date, time, ...rest] = data;
                const dateTime = new Date();
                if (time) {
                  const [hh, mm] = time.split(':');
                  dateTime.setHours(hh, mm, 0);
                }

                return {
                  index,
                  data: [
                    format(new Date(date), 'MMM dd, yyyy'),
                    time ? format(new Date(dateTime), 'hh:mm a') : '',
                    ...rest
                  ]
                };
              });

              return buildHead(columns) + buildBody(newData);
            }
          }}
        />

        {/* <DeleteDialog
          open={isDeleteOpen}
          handleClose={() => {
            setIsDeleteOpen(false);
            setSelectedId('');
          }}
          handleOk={handleDeleteConfirm}
          message={'Are you sure you want to delete E-Plan ?'}
        />
        {isNewOpen && (
          <NewPlan
            open={isNewOpen}
            plan={plan}
            userId={userId}
            handleClose={() => {
              setIsNewOpen(false);
              setPlan(null);
            }}
            handleOk={() => {
              setIsNewOpen(false);
              setPlan(null);
              dispatch(getAllPlan({ userId, page: 1, pageSize: 10 }));
            }}
          />
        )} */}
      </CardContent>
    </Card>
  );
};

List.propTypes = {
  name: PropTypes.string
};

export default List;
