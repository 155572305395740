import React, { useEffect, useState } from 'react';
import { Card, CardContent, TablePagination } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import MUIDataTable from 'mui-datatables';
import { format } from 'date-fns';
import { getAllUserMechanicNeeds } from '../../../redux/actions/users';
import CustomeSearch from '../../../components/CustomSearch';
import Filters from '../../../components/CommonFilter';

const NeedsList = () => {
  const dispatch = useDispatch();
  const { userMechanicNeeds, users } = useSelector(({ users }) => users);

  const [initialFormState, setInitialFormState] = useState({
    fromDate: new Date(),
    toDate: new Date(),
    userId: ''
  });

  const columns = [
    {
      name: 'user',
      label: 'Name',
      options: {
        customBodyRender: (value) => {
          return value?.firstname + ' ' + value?.lastname;
        },
        sortCompare: (order) => {
          return (obj1, obj2) => {
            const val1 = obj1.data?.firstname;
            const val2 = obj2.data?.firstname;
            // return (val1 - val2) * (order === 'asc' ? 1 : -1);
            return order === 'asc' ? val2.localeCompare(val1) : val1.localeCompare(val2);
          };
        }
      }
    },
    {
      name: 'date',
      label: 'Date',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return format(new Date(value), 'MMM dd, yyyy');
        }
      }
    },
    {
      name: 'itemNeeded',
      label: 'Item Needed'
    },
    {
      name: 'needBy',
      label: 'Need By',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return format(new Date(value), 'MMM dd, yyyy');
        }
      }
    },
    {
      name: 'project',
      label: 'Project',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value.name;
        },
        sortCompare: (order) => {
          return (obj1, obj2) => {
            const val1 = parseInt(obj1.data?.id, 10);
            const val2 = parseInt(obj2.data?.id, 10);
            return (val1 - val2) * (order === 'asc' ? 1 : -1);
          };
        },
        filterOptions: {
          logic: (location, filters, row) => {
            if (filters.length) {
              return filters.some((filter) => !location.includes(filter));
            }
            return false;
          }
        }
      }
    }
  ];

  useEffect(() => {
    const data = getFilterObj(initialFormState);
    dispatch(getAllUserMechanicNeeds({ page: 1, pageSize: 100, ...data }));
  }, []);

  const renderCustomSearch = (searchText, handleSearch, hideSearch, options) => (
    <CustomeSearch handleSearch={handleSearch} hideSearch={hideSearch} />
  );

  const pageChange = (page) => {
    const data = getFilterObj(initialFormState);
    dispatch(getAllUserMechanicNeeds({ page, pageSize: userMechanicNeeds?.pageSize, ...data }));
  };

  const pageSizeChange = (pageSize) => {
    const data = getFilterObj(initialFormState);
    dispatch(getAllUserMechanicNeeds({ page: userMechanicNeeds?.page, pageSize, ...data }));
  };

  const filter = (values) => {
    setInitialFormState(values);
    const data = getFilterObj(values);
    dispatch(getAllUserMechanicNeeds({ page: 1, pageSize: 100, ...data }));
  };

  const getFilterObj = (values) => {
    return {
      userId: values.userId,
      startDate: format(values.fromDate, 'yyyy-MM-dd'),
      endDate: format(values.toDate, 'yyyy-MM-dd')
    };
  };

  const renderFooter = () => {
    return (
      <tbody>
        <tr>
          <TablePagination
            rowsPerPage={userMechanicNeeds?.pageSize || 100}
            count={userMechanicNeeds?.count ||0}
            page={userMechanicNeeds?.page ? userMechanicNeeds.page - 1 : 1}
            onPageChange={(event, page) => pageChange(page + 1)}
            onRowsPerPageChange={(event) => pageSizeChange(event.target.value)}
            rowsPerPageOptions={[10, 20, 40, 80, 100]}
          />
        </tr>
      </tbody>
    );
  };

  return (
    <Card className="">
      <Filters
        users={users}
        onFilter={filter}
        initialFormState={initialFormState}
        setInitialFormState={setInitialFormState}
      />
      <CardContent>
        <MUIDataTable
          title="Mechanic Need"
          data={userMechanicNeeds?.data || []}
          columns={columns}
          options={{
            filter: false,
            responsive: 'standard',
            selectableRows: 'none',
            pagination: false,
            elevation: 0,
            rowsPerPageOptions: [10, 20, 40, 80, 100],
            customSearchRender: renderCustomSearch,
            customFooter: renderFooter,
            print: false,
            downloadOptions: {
              filename: `Needs.csv`
            },
            viewColumns: false,
            onDownload: (buildHead, buildBody, columns, values) => {
              const newData = values.map(({ index, data }) => {
                const [{ firstname, lastname }, date, need, needBy, project] = data;
                return {
                  index,
                  data: [
                    `${firstname} ${lastname}`,
                    format(new Date(date), 'MMM dd, yyyy'),
                    need,
                    format(new Date(needBy), 'MMM dd, yyyy'),
                    project?.name
                  ]
                };
              });

              return buildHead(columns) + buildBody(newData);
            }
          }}
        />
      </CardContent>
    </Card>
  );
};

NeedsList.propTypes = {};

export default NeedsList;
