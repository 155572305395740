import React from 'react';

import './privacy.scss';

const PrivacyPolicy = (props) => {
  return (
    <div className="privacy">
      <h1 className='privacy__header'>Privacy Policy</h1>
    </div>
  );
};

export default PrivacyPolicy;
