import React, { useEffect, useState } from 'react';
import { Button, Card, CardContent, IconButton, Menu, MenuItem } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import MUIDataTable from 'mui-datatables';
import CustomeSearch from '../../../components/CustomSearch';
import { getAllUsers, removeUser } from '../../../redux/actions/users';
import { MoreVert } from '@material-ui/icons';
import DeleteDialog from '../../../components/DeleteDialog';
import NewUser from '../NewUser';

import './userlist.scss';
import { useHistory } from 'react-router-dom';

const UserList = (props) => {
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [selectedUserRole, setSelectedUserRole] = useState(0);

  const [isNewOpen, setIsNewOpen] = useState(false);
  const [user, setUser] = useState(null);

  const dispatch = useDispatch();
  const { users } = useSelector(({ users }) => users);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const history = useHistory();

  useEffect(() => {
    dispatch(getAllUsers());
  }, []);

  const columns = [
    {
      name: 'firstname',
      label: 'First Name'
    },
    {
      name: 'lastname',
      label: 'Last Name'
    },
    {
      name: 'email',
      label: 'Email'
    },
    {
      name: 'username',
      label: 'Username'
    },
    {
      name: 'role',
      label: 'Role',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value === 1 ? 'Admin' : 'User';
        }
      }
    },
    {
      name: 'isActive',
      label: 'Is Active',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? 'Active' : 'Inactive';
        },
        filter: false
      }
    },
    {
      name: 'id',
      label: 'Action',
      options: {
        sort: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={(e) => handleClick(e, value, tableMeta?.rowData[4])}
              >
                <MoreVert />
              </IconButton>
              <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    width: '20ch'
                  }
                }}
              >
                <MenuItem onClick={() => editClickHandler(selectedId)}>Edit</MenuItem>
                <MenuItem onClick={() => onDelete(selectedId)}>Delete</MenuItem>
                {selectedUserRole === 2 && <MenuItem onClick={() => logs(selectedId)}>Daily Log</MenuItem>}
                {selectedUserRole === 2 && <MenuItem onClick={() => needs(selectedId)}>Mechanic Needs</MenuItem>}
                {selectedUserRole === 2 && <MenuItem onClick={() => plans(selectedId)}>E-Plan</MenuItem>}
              </Menu>
            </>
          );
        }
      }
    }
  ];

  const handleClick = (event, value, role) => {
    setSelectedId(value);
    setSelectedUserRole(role);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const editClickHandler = (id) => {
    handleClose(false);
    const editable = users.find((user) => user.id === id);
    setUser(editable);
    setIsNewOpen(true);
  };

  const onDelete = (id) => {
    handleClose(false);
    setIsDeleteOpen(true);
    setSelectedId(id);
  };

  const handleDeleteConfirm = () => {
    dispatch(removeUser(selectedId));
    setSelectedId('');
    setSelectedUserRole(0);
    setIsDeleteOpen(false);
  };

  const logs = (userId) => {
    handleClose(false);
    history.push(`/daily-logs/${userId}`);
  };

  const needs = (userId) => {
    handleClose(false);
    history.push(`/mechanic-needs/${userId}`);
  };

  const plans = (userId) => {
    handleClose(false);
    history.push(`/driver-plans/${userId}`);
  };

  const renderCustomSearch = (searchText, handleSearch, hideSearch, options) => (
    <CustomeSearch handleSearch={handleSearch} hideSearch={hideSearch} />
  );

  return (
    <Card className="userlist">
      <CardContent>
        <div className="userlist__header">
          <Button size="medium" color="primary" variant="contained" onClick={() => setIsNewOpen(true)}>
            New User
          </Button>
        </div>
        <MUIDataTable
          title="Users"
          data={users || []}
          columns={columns}
          options={{
            filterType: 'textField',
            responsive: 'standard',
            selectableRows: 'none',
            pagination: true,
            elevation: 0,
            rowsPerPageOptions: [10, 20, 40, 80, 100],
            customSearchRender: renderCustomSearch,
            print: false,
            downloadOptions: {
              filename: `users.csv`
            },
            viewColumns: false,
            rowsPerPage: 100
          }}
        />
      </CardContent>

      <DeleteDialog
        open={isDeleteOpen}
        handleClose={() => {
          setIsDeleteOpen(false);
          setSelectedId('');
          setSelectedUserRole(0);
        }}
        handleOk={handleDeleteConfirm}
        message={'Are you sure you want to delete User ?'}
      />
      {isNewOpen && (
        <NewUser
          open={isNewOpen}
          user={user}
          handleClose={() => {
            setIsNewOpen(false);
            setUser(null);
            setSelectedId('');
            setSelectedUserRole(0);
          }}
          handleOk={() => {
            setIsNewOpen(false);
            setUser(null);
            setSelectedId('');
            setSelectedUserRole(0);
          }}
        />
      )}
    </Card>
  );
};

UserList.propTypes = {};

export default UserList;
