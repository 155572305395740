import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import GridContainer from '../../../components/GridContainer';
import TextfieldWrapper from '../../../components/FormikWrappers/TextfieldWrapper';
import CheckBoxWrapper from '../../../components/FormikWrappers/CheckboxWrapper';

import { Grid } from '@material-ui/core';
import { createProject, updateProject } from '../../../redux/actions/project';
import { useDispatch } from 'react-redux';

import './newproject.scss';

const NewProject = ({ open, handleClose, handleOk, project }) => {
  const formRef = useRef();
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('This is Required Field'),
    description: Yup.string().required('This is Required Field'),
    status: Yup.bool()
  });

  const [initialFormState, setInitialFormState] = useState({
    name: '',
    description: '',
    status: false
  });

  useEffect(() => {
    if (project && project.id) {
      setInitialFormState({
        name: project?.name,
        description: project?.description || '',
        status: project.status
      });
    }
  }, []);

  const submitForm = (values) => {
    if (project && project.id) {
      dispatch(
        updateProject(project.id, values, () => {
          handleOk();
        })
      );
    } else {
      dispatch(
        createProject(values, () => {
          handleOk();
        })
      );
    }
  };

  const save = () => {
    formRef.current.submitForm();
  };

  return (
    <Dialog maxWidth={'md'} fullWidth onClose={handleClose} open={open}>
      <DialogTitle className="">{project ? 'Update Project' : 'New Project'}</DialogTitle>
      <DialogContent dividers={true}>
        <Formik
          innerRef={formRef}
          initialValues={{ ...initialFormState }}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={(values) => submitForm(values)}
        >
          {() => (
            <Form>
              <GridContainer className="newuser__container">
                <Grid item xs={12}>
                  <TextfieldWrapper autoComplete="off" name="name" label="Project Name" placeholder="Project Name" />
                </Grid>

                <Grid item xs={12}>
                  <TextfieldWrapper
                    autoComplete="off"
                    name="description"
                    label="Description"
                    placeholder="Description"
                  />
                </Grid>
                <Grid item xs={12}>
                  <CheckBoxWrapper name="status" label="Status" />
                </Grid>
              </GridContainer>
            </Form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        <Button onClick={save} color="primary">
          Save
        </Button>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

NewProject.propTypes = {
  project: PropTypes.object,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleOk: PropTypes.func
};

export default NewProject;
