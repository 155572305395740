import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import PageContainer from '../../components/PageComponents/layouts/PageContainer';
import { getOneProject } from '../../redux/actions/project';
import List from './List';

const breadcrumbs = [
  { label: 'Projects', link: '/projects' },
  { label: 'Drawings', isActive: true }
];

const Drawings = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { projects } = useSelector((state) => state.projects);
  const { projectId } = useParams();
  const [project, setProject] = useState(null);

  useEffect(() => {
    if (projects.length > 0) {
      const item = projects.find((u) => u.id === Number(projectId));
      if (item) {
        setProject(item);
      } else {
        getOne();
      }
    } else {
      getOne();
    }
  }, []);

  const getOne = () => {
    dispatch(
      getOneProject(projectId, (item) => {
        if (!item) {
          history.goBack();
        }
        setProject(item);
      })
    );
  };

  return (
    <PageContainer heading={`Drawings of ${project?.name || ""}`.toUpperCase()} breadcrumbs={breadcrumbs}>
      <List projectId={Number(projectId)} />
    </PageContainer>
  );
};

Drawings.propTypes = {};

export default Drawings;
