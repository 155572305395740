import produce from 'immer';
import { SET_USER, UPDATE_LOAD_USER, UPDATE_AUTH_USER, LOGOUT } from '../constants/auth';

const INIT_STATE = {
  authUser: null,
  loadUser: false
};

export default produce((draft, action) => {
  switch (action.type) {
    case SET_USER:
      draft.authUser = action.payload;
      draft.loadUser = true;
      break;

    case UPDATE_LOAD_USER:
      draft.loadUser = action.payload;
      break;

    case UPDATE_AUTH_USER:
      draft.authUser = action.payload;
      draft.loadUser = true;
      break;
    case LOGOUT:
      draft.authUser = null;
      draft.loadUser = true;
      break;
    default:
      return draft;
  }
}, INIT_STATE);
