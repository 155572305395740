import {
  SET_ALL_PROJECTS,
  SET_ALL_DRAWINGS,
  CREATE_DRAWING,
  DELETE_DRAWING,
  SET_ALL_PROJECTS_PAIGINATION,
  CREATE_PROJECT_PAIGINATION,
  UPDATE_PROJECT_PAIGINATION,
  DELETE_PROJECT_PAIGINATION,
  SET_ALL_MEDIA
} from '../constants/project';
import {
  create,
  getAll,
  getOne,
  remove,
  update,
  addDrawing,
  deleteOneDrawing,
  getAllDrawing,
  getOneDrawing,
  getAllProjects as getAllProjectsService,
  getAllMedia
} from '../../services/projects';
import { setSuccessMessage } from './common';

export const getAllProjects = () => {
  return async (dispatch) => {
    try {
      const res = await getAll();
      dispatch({
        type: SET_ALL_PROJECTS,
        payload: res.data
      });
    } catch (err) {}
  };
};

export const getAllProjectsWithPagination = (reqData) => {
  return async (dispatch) => {
    try {
      const res = await getAllProjectsService(reqData);
      dispatch({
        type: SET_ALL_PROJECTS_PAIGINATION,
        payload: res.data
      });
    } catch (err) {}
  }
}

export const createProject = (project, cb) => {
  return async (dispatch, getState) => {
    try {
      const res = await create(project);
      const projects ={
        ...getState().projects.allProjects,
        data: [...getState().projects.allProjects.data, res.data],
      }
      dispatch({
        type: CREATE_PROJECT_PAIGINATION,
        payload: projects
      });
      dispatch(setSuccessMessage('Project created successfully'));
      cb();
    } catch (err) {}
  };
};

export const updateProject = (id, project, cb) => {
  return async (dispatch, getState) => {
    try {
      const res = await update(id, project);
      const projects = [...getState().projects.allProjects?.data];
      const index = projects.findIndex((p) => p.id === id);
      projects[index] = res.data;

      dispatch({
        type: UPDATE_PROJECT_PAIGINATION,
        payload: {
          ...getState().projects.allProjects,
          data: projects
        }
      });
      dispatch(setSuccessMessage('Project updated successfully'));
      cb();
    } catch (err) {}
  };
};

export const removeProject = (id) => {
  return async (dispatch, getState) => {
    try {
      const projects = [...getState().projects.allProjects?.data];
      await remove(id);
      const index = projects.findIndex((p) => p.id === id);
      projects.splice(index, 1);

      dispatch({
        type: DELETE_PROJECT_PAIGINATION,
        payload: {
          ...getState().projects.allProjects,
          data: projects
        }
      });
      dispatch(setSuccessMessage('Project deleted successfully'));
    } catch (err) {}
  };
};

export const getOneProject = (id, cb) => {
  return async (dispatch) => {
    try {
      const res = await getOne(id);
      cb(res.data);
    } catch (err) {}
  };
};

export const getAllDrawingsForProject = (id) => {
  return async (dispatch) => {
    try {
      const res = await getAllDrawing(id);
      dispatch({
        type: SET_ALL_DRAWINGS,
        payload: res.data
      });
    } catch (err) {}
  };
};

export const insertDrawings = (id, files, cb) => {
  return async (dispatch, getState) => {
    try {
      const drawings = [...getState().projects.drawings];
      const res = await addDrawing(id, files);
      res.data.forEach((data) => {
        drawings.push(data);
      });
      cb();
      dispatch({
        type: CREATE_DRAWING,
        payload: drawings
      });

      dispatch(setSuccessMessage('Drawing added successfully'));
    } catch (err) {}
  };
};

export const deleteDrawing = (id) => {
  return async (dispatch, getState) => {
    const drawings = [...getState().projects.drawings];
    await deleteOneDrawing(id);
    const index = drawings.findIndex((p) => p.id === id);
    drawings.splice(index, 1);
    dispatch({
      type: DELETE_DRAWING,
      payload: drawings
    });
  };
};

export const getDrawing = (fileId, cb) => {
  return async () => {
    try {
      const res = await getOneDrawing(fileId);
      cb(res.data);
    } catch (err) {}
  };
};


export const getAllMediasForProject = (id) => {
  return async (dispatch) => {
    dispatch({
      type: SET_ALL_MEDIA,
      payload: []
    });
    try {
      const res = await getAllMedia(id);
      dispatch({
        type: SET_ALL_MEDIA,
        payload: res.data
      });
    } catch (err) {}
  };
};
