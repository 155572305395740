import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import GridContainer from '../../../components/GridContainer';
import TextfieldWrapper from '../../../components/FormikWrappers/TextfieldWrapper';
import CheckBoxWrapper from '../../../components/FormikWrappers/CheckboxWrapper';
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import { updateUser, createUser } from '../../../redux/actions/users';

import './newuser.scss';
import { useDispatch } from 'react-redux';

const NewUser = ({ open, handleClose, handleOk, user }) => {
  const formRef = useRef();
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    firstname: Yup.string().required('This is Required Field'),
    lastname: Yup.string().required('This is Required Field'),
    email: Yup.string().required('This is Required Field').email('Invalid Email'),
    username: Yup.string().required('This is Required Field'),
    password: Yup.string()
      .required('This is Required Field')
      .min(6, 'Password must be at least 6 characters long')
      .max(25, 'Password must be less than 25 characters long'),

    confirmPassword: Yup.string().when('password', {
      is: (val) => !!(val && val.length > 0),
      then: Yup.string()
        .required('This is Required Field')
        .oneOf([Yup.ref('password')], 'Passwords must match')
    }),
    isActive: Yup.bool(),
    role: Yup.string().required('This is Required Field')
  });

  const [initialFormState, setInitialFormState] = useState({
    firstname: '',
    lastname: '',
    email: '',
    username: '',
    password: '',
    confirmPassword: '',
    isActive: false,
    role: 2
  });
  const [schema, setSchema] = useState(validationSchema);

  useEffect(() => {
    if (user && user.id) {
      setInitialFormState({
        firstname: user?.firstname || '',
        lastname: user?.lastname || '',
        email: user?.email || '',
        username: user?.username || '',
        isActive: user.isActive,
        role: user.role || 2
      });
      setSchema(
        Yup.object().shape({
          firstname: Yup.string().required('This is Required Field'),
          lastname: Yup.string().required('This is Required Field'),
          email: Yup.string().required('This is Required Field').email('Invalid Email'),
          username: Yup.string().required('This is Required Field'),
          password: Yup.string()
            .min(6, 'Password must be at least 6 characters long')
            .max(25, 'Password must be less than 25 characters long'),

          confirmPassword: Yup.string().when('password', {
            is: (val) => !!(val && val.length > 0),
            then: Yup.string()
              .required('This is Required Field')
              .oneOf([Yup.ref('password')], 'Passwords must match')
          }),
          isActive: Yup.bool(),
          role: Yup.string().required('This is Required Field')
        })
      );
    }
  }, []);

  const submit = (values) => {
    if (user && user.id) {
      dispatch(
        updateUser(user.id, values, () => {
          handleOk();
        })
      );
    } else {
      dispatch(
        createUser(values, () => {
          handleOk();
        })
      );
    }
  };

  const save = () => {
    formRef.current.submitForm();
  };
  return (
    <Dialog maxWidth={'md'} fullWidth onClose={handleClose} open={open}>
      <DialogTitle className="">{user ? 'Update User' : 'New User'}</DialogTitle>
      <DialogContent dividers={true}>
        <Formik
          innerRef={formRef}
          initialValues={{ ...initialFormState }}
          validationSchema={schema}
          enableReinitialize
          onSubmit={(values) => submit(values)}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <GridContainer className="newuser__container">
                <Grid item xs={12} md={6}>
                  <TextfieldWrapper autoComplete="off" name="firstname" label="Firstname" placeholder="Firstname" />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextfieldWrapper autoComplete="off" name="lastname" label="Lastname" placeholder="Lastname" />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextfieldWrapper autoComplete="off" name="email" label="Email" placeholder="Email" />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextfieldWrapper autoComplete="off" name="username" label="Username" placeholder="Username" />
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Role</InputLabel>
                    <Select
                      value={values.role}
                      label="Role"
                      onChange={({ target: { value } }) => setFieldValue('role', value)}
                    >
                      <MenuItem value={1} >Admin</MenuItem>
                      <MenuItem value={2} >Field</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextfieldWrapper type="password" name="password" label="Password" placeholder="Password" />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextfieldWrapper
                    type="password"
                    name="confirmPassword"
                    label="Confirm Password"
                    placeholder="Confirm Password"
                  />
                </Grid>
                <Grid item xs={12}>
                  <CheckBoxWrapper name="isActive" label="Is Active" />
                </Grid>
              </GridContainer>
            </Form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        <Button onClick={save} color="primary">
          Save
        </Button>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

NewUser.propTypes = {
  user: PropTypes.object,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleOk: PropTypes.func
};

export default NewUser;
