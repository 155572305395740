export const SET_LANGUAGE = 'SET_LANGUAGE';
export const FETCH_ERROR = 'FETCH_ERROR';
export const FETCH_START = 'FETCH_START';
export const FETCH_SUCCESS = 'FETCH_SUCCESS';
export const RESET_ERROR = 'RESET_ERROR';
export const STOP_LOADING = 'STOP_LOADING';
export const SHOW_ERROR = 'SHOW_ERROR';
export const CLEAR_REDUCER = 'CLEAR_REDUCER';
export const SET_SUCCESS_MESSAGE = 'SET_SUCCESS_MESSAGE';
export const RESET_SUCCESS_MESSAGE = 'RESET_SUCCESS_MESSAGE';
